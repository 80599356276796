// @font-face {
//   font-family: 'Metropolis-Thin';
//   src: url('fonts/metropolis/Metropolis-Thin.otf') format('opentype');
// }
// @font-face {
//   font-family: 'Metropolis-Thin';
//   src: url('fonts/metropolis/Metropolis-Thin.otf') format('opentype');
// }
body {
  box-sizing: content-box;
  padding: 0 !important;

  font-family: SF Pro Display, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;

  // min-width: 1000px;

  margin: auto;

  background-color: #f8f8f8;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  // margin-left: calc(100vw - 100%);
  margin: 0;
  padding: 0;
  overflow-y: scroll !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  -webkit-print-color-adjust: exact;
}
